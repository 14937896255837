<template>
  <div>
    <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
      <div class="wrapper-select">
        <span>Show</span>
        <div class="select-row">
          <el-select
            class="select-primary pagination-select"
            size="mini"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <span>Row</span>
      </div>
      <div class="form-search">
        <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
      </div>
    </div>
    <el-table
      :data="queriedData"
      row-key="id"
      header-row-class-name="thead-light"
      @sort-change="sortChange"
      @selection-change="selectionChange"
    >
      <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
      <el-table-column min-width="200px" align="right" label="Actions">
        <div slot-scope="{$index, row}" class="d-flex">
          <base-button @click.native="showEdit($index, row)" type="edit-vr" size="sm">
            <span>Edit</span>
          </base-button>
          <base-button @click.native="showPopDelete(row)" type="danger" size="sm">
            <span>Delete</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class>
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          <span
            v-if="selectedRows.length"
          >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      ></base-pagination>
    </div>
    <modal :show.sync="showModlaEdit" modal-classes="modal-secondary" size="lg">
      <template slot="header">
        <h3 class="modal-title list-lesson">Edit Exam Question</h3>
      </template>
      <template slot="close-button"></template>
      <validation-observer v-slot="{ handleSubmit }" ref="formAddNewQuestion">
        <form role="form" @submit.prevent="handleSubmit(handleEdit)">
          <div class="row pb-2 wrapper-preview">
            <div v-if="addExamQuestion.image.preview" class="wrapper-img">
              <img :src="addExamQuestion.image.preview" class="preview-image" />
              <p>{{ addExamQuestion.image.value.name }}</p>
            </div>
            <div class="col-md-6">
              <base-input
                label="Exam Question"
                class="mb-1"
                name="exam"
                placeholder="Write here..."
                v-model="addExamQuestion.question"
                :rules="{ required: true }"
              ></base-input>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-9 wrapper-image-exam">
                  <!-- <base-button size="lg" type="edit-vr"> -->
                  <label class="label-upload">
                    <div class="btn base-button btn-edit-vr btn-lg">Change Image</div>
                    <input
                      hidden="true"
                      type="file"
                      accept="image/*"
                      ref="imageFile"
                      @change="previewImage"
                    />
                  </label>
                  <!-- </base-button> -->
                  <!-- <div class="wrapper-text">
                    <p>{{ addExamQuestion.image.preview }}</p>
                  </div>-->
                </div>
                <!-- <div class="col-3">
                  <base-input
                    label="Point"
                    name="Point"
                    :disabled="true"
                    v-model="addExamQuestion.point"
                  ></base-input>
                </div>-->
                <div class="col-3">
                  <!-- <base-input label="Key" name="Key" v-model="addExam.key"></base-input> -->
                  <base-input label="Key" name="Key" :rules="{ required: true }">
                    <select class="form-control" v-model="addExamQuestion.key">
                      <template v-if="listKey">
                        <option
                          v-for="choose in listKey"
                          :key="choose.name"
                          :value="choose.value"
                        >{{ choose.name }}</option>
                      </template>
                    </select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-for="(option, idx) in addExamQuestion.options" :key="idx">
            <div class="col-md-6">
              <base-input
                :label="option.name"
                class="mb-1"
                :name="option.name"
                placeholder="Write here..."
                v-model="option.value"
                :rules="{ required: true }"
              ></base-input>
            </div>
          </div>
          <!-- <template slot="footer"> -->
          <div class="text-right mt-4">
            <base-button type="outline-vr" @click="handleCancelEditQuestion">Cancel</base-button>
            <!-- <base-button type="success" @click="AddFormQuestion">Add New Question</base-button> -->
            <base-button type="detail-vr" native-type="submit">Save & Exit</base-button>
          </div>
          <!-- </template> -->
        </form>
      </validation-observer>
    </modal>
    <modal :show.sync="showModalDelete" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Delete Exam Question</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="delete-exam">delete these exam question</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button outline type="primary" class="my-4" @click="showModalDelete = false">Cancel</base-button>
          <base-button type="danger" class="my-4" @click="handleDelete">Delete</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="showCancelEditQuestion" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Cancel Editing?</h6>
      </template>
      <span class="body-modal">
        Changes you made so far
        <span class="add-new-question">will not be saved</span>!
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button type="outline-vr" class="my-4" @click="handleBackEditQuestion">Back</base-button>
          <base-button type="danger" class="my-4" @click="handleCloseModaalAddNewQuestion">Leave</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="loading.EditQuestion" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import { getListQuestionByIdCourse, updateQuestion, deleteQuestion, getDetailQuestionById } from '@/services/exam'
import Fuse from "fuse.js";
import LoadingPanel from '@/components/LoadingPanel.vue';
export default {
  name: 'ListExam',
  components: {
    BasePagination,
    LoadingPanel,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    activity: {
      type: Boolean
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
      propsToSearch: ['updatedAt', 'question', 'point'],
      tableColumns: [
        {
          prop: 'updatedAt',
          label: 'Date Update',
          minWidth: 180,
          sortable: true
        },
        {
          prop: 'question',
          label: 'Exam Question',
          minWidth: 280,
          sortable: true
        },
        {
          prop: 'point',
          label: 'Point',
          minWidth: 150,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      showModlaEdit: false,
      showModalDelete: false,
      showCancelEditQuestion: false,
      selectedDelete: null,
      editData: {
        question: "",
        key: "",
        point: 0,
        examId: 0
      },
      listKey: [
        { name: 'A', value: 'A', },
        { name: 'B', value: 'B', },
        { name: 'C', value: 'C', },
        { name: 'D', value: 'D', },
        { name: 'E', value: 'E', }
      ],
      addExamQuestion:
      {
        id: 0,
        question: '',
        options: [
          { key: 'A', name: 'Option A', value: '' },
          { key: 'B', name: 'Option B', value: '' },
          { key: 'C', name: 'Option C', value: '' },
          { key: 'D', name: 'Option D', value: '' },
          { key: 'E', name: 'Option E', value: '' }],
        image: { value: {}, preview: '' },
        point: 5,
        key: 'A'
      },
      loading: {
        EditQuestion: false
      }
    }
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      } else {
        if (this.searchQuery) {
          result = [];
        }
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    previewImage() {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (types.includes(this.$refs.imageFile.files[0].type)) {
        if ((this.$refs.imageFile.files[0].size / 1024).toFixed(2) < 3000) {
          this.addExamQuestion.image.value = this.$refs.imageFile.files[0];
          this.addExamQuestion.image.preview = URL.createObjectURL(this.addExamQuestion.image.value);
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "File size exceeds the limit"
          });
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be png/jpg/jpeg"
        });
      }
    },
    async showEdit(index, row) {
      try {
        const { data } = await getDetailQuestionById(row.id)
        this.addExamQuestion.id = data.id
        this.addExamQuestion.key = data.key
        this.addExamQuestion.question = data.question
        this.addExamQuestion.point = data.point
        this.addExamQuestion.image.preview = data.image_url
        this.addExamQuestion.image.value.name = data.image_name
        data.options.map((item, idx) => {
          if (this.addExamQuestion.options[idx].key === item.key) {
            this.addExamQuestion.options[idx].value = item.option
            this.addExamQuestion.options[idx].id = item.id
          }
        })
        this.showModlaEdit = true
      } catch (error) {
        console.error(error)
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    handleCancelEditQuestion() {
      this.showCancelEditQuestion = true
      this.showModlaEdit = false
    },
    handleBackEditQuestion() {
      this.showCancelEditQuestion = false
      setTimeout(() => {
        this.showModlaEdit = true
      }, 100);
    },
    handleCloseModaalAddNewQuestion() {
      this.showCancelEditQuestion = false
      this.showModlaEdit = false
      this.$refs.imageFile.value = null
    },
    async handleEdit() {
      try {
        this.showModlaEdit = false
        const form = new FormData();
        form.append('id', this.addExamQuestion.id);
        form.append('question', this.addExamQuestion.question);
        form.append('point', this.addExamQuestion.point);
        form.append('key', this.addExamQuestion.key);
        form.append('image', this.addExamQuestion.image.value);
        this.addExamQuestion.options.map((item, idx) => {
          form.append(`options[${idx}][id]`, item.id);
          form.append(`options[${idx}][option]`, item.value);
          form.append(`options[${idx}][key]`, item.key);
        })
        this.loading.EditQuestion = true
        const { data } = await updateQuestion(form)
        this.loading.EditQuestion = false
        this.tableData = data.question
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.addExamQuestion.image.value = {}
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Update Succesfull"
        });
      } catch (error) {
        this.loading.EditQuestion = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.showModlaEdit = false
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    showPopDelete(value) {
      this.showModalDelete = true
      this.selectedDelete = value
    },
    async handleDelete() {
      try {
        const { data } = await deleteQuestion(this.selectedDelete.id)
        this.tableData = data.question
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.showModalDelete = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Delete Succesfull"
        });
        this.selectedDelete = null
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else if (error === "EMPTY_RESULT") {
          this.tableData = []
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          this.showModalDelete = false
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "Delete Succesfull"
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getListQuestion() {
      try {
        const { data } = await getListQuestionByIdCourse(this.$route.params.id)
        this.tableData = data.question
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.getListQuestion()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch
          .search(this.searchQuery)
          .map(({ item }) => item);
      }
      this.searchedData = result;
    },
    activity() {
      this.getListQuestion()
    },
    showModlaEdit(val) {
      if (!val) {
        this.addExamQuestion.image.value = {}
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}
.modal-title {
  &.list-lesson {
    padding: 0.5rem 1rem;
  }
  &.padding-title {
    padding: 1.25 rem;
    border-bottom: 0 solid #e9ecef;
    border-top-left-radius: 0.4375 rem;
    border-top-right-radius: 0.4375 rem;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .delete-exam {
    color: $alert-ar-vr;
  }
  .add-new-question {
    color: $status-failed-ar-vr;
  }
}
.wrapper-image-exam {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 10px;
  .wrapper-text {
    overflow-x: scroll;
    max-width: 60px;

    p {
      white-space: nowrap;
      font-size: 14px;
      margin: 0;
    }
  }
}
// to preview image at add new exam question
.wrapper-preview {
  position: relative;
  .wrapper-img {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 200px;
    height: 200px;
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;

    p {
      margin: 10px 0 0 0;
      text-align: center;
    }
    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>