<template>
  <div>
    <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
      <div class="wrapper-select">
        <span>Show</span>
        <div class="select-row">
          <el-select
            class="select-primary pagination-select"
            size="mini"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <span>Row</span>
      </div>
      <div class="form-search">
        <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
      </div>
    </div>
    <el-table
      :data="queriedData"
      row-key="id"
      header-row-class-name="thead-light"
      @sort-change="sortChange"
      @selection-change="selectionChange"
    >
      <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
      <el-table-column min-width="300px" align="right" label="Actions">
        <div slot-scope="{$index, row}" class="d-flex">
          <base-button @click.native="handlePopUpEdit($index, row)" type="edit-vr" size="sm">
            <span>Edit</span>
          </base-button>
          <base-button
            v-if="!swapList[0].id"
            @click.native="swapList[0].id = row.id"
            type="success"
            size="sm"
          >
            <span>Swap</span>
          </base-button>
          <base-button
            v-else-if="swapList[0].id === row.id"
            type="danger"
            size="sm"
            @click.native="cancelSwap()"
          >
            <span>Cancel</span>
          </base-button>
          <base-button v-else @click.native="handleSwap(row)" type="success" size="sm">
            <span>This</span>
          </base-button>
          <base-button @click.native="showPopDelete(row)" type="danger" size="sm">
            <span>Delete</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class>
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          <span
            v-if="selectedRows.length"
          >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      ></base-pagination>
    </div>
    <modal size="lg" :show.sync="showModalEditLesson" modal-classes="modal-secondary">
      <template slot="header">
        <h3 class="modal-title list-lesson">Edit Lesson</h3>
      </template>
      <template slot="close-button"></template>
      <validation-observer v-slot="{ handleSubmit }" ref="formeditLesson">
        <form role="form" @submit.prevent="handleSubmit(handleEditLesson)">
          <div class="row">
            <div class="col-sm-7">
              <base-input
                label="Title Lesson"
                class="mb-3"
                name="Title"
                placeholder="Write title here..."
                v-model="editLesson.title"
                :rules="{ required: true }"
              ></base-input>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label for="pdf" class="form-control-label">PDF File</label>
                <div class="has-label">
                  <div class="wrapper-input-video">
                    <div v-if="!isEditLesson" class="thumbnail-video">
                      <div class="back-video open-pdf" @click="handlePreviewPDF">
                        <h6>{{ editLesson.pdf.preview }}</h6>
                      </div>
                    </div>
                    <iframe
                      v-else
                      :src="editLesson.pdf.preview"
                      frameborder="0"
                      width="150px"
                      height="200px"
                    ></iframe>
                    <base-button size="sm" outline type="primary" class="button-upload">
                      <label class="label-upload">
                        <div>Upload PDF</div>
                        <input
                          class="file-upload"
                          @change="uploadPdf"
                          type="file"
                          accept="application/pdf"
                          ref="pdfFile"
                        />
                      </label>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <template slot="footer"> -->
          <div class="text-right mt-4">
            <base-button outline type="primary" @click="handleCancel">Cancel</base-button>
            <base-button type="edit-vr" native-type="submit">Save Changes</base-button>
          </div>
          <!-- </template> -->
        </form>
      </validation-observer>
    </modal>
    <modal :show.sync="showModalSubmitEditLesson" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Edit New Lesson</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="edit-lesson">save these change</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button
            outline
            type="primary"
            class="my-4"
            @click="handleSubmitEditLessonBack"
          >Cancel</base-button>
          <base-button type="edit-vr" class="my-4" @click="handleSubmitEditLesson">Save Changes</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="showModalDelete" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Delete Lesson</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="delete-lesson">delete these lesson</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button outline type="primary" class="my-4" @click="showModalDelete = false">Cancel</base-button>
          <base-button type="danger" class="my-4" @click="handleDelete">Delete</base-button>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import { getLessonByCourse, getFileLesson, deleteLesson, editLesson, swapOrderLesson } from '@/services/lesson'
import { mapActions } from 'vuex'
import Fuse from "fuse.js";
export default {
  name: 'ListParticipant',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    activity: {
      type: Boolean
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
      propsToSearch: ['updated', 'title', 'order'],
      tableColumns: [
        {
          prop: 'updated',
          label: 'Date Update',
          minWidth: 220,
          sortable: true
        },
        {
          prop: 'order',
          label: 'Lesson Number',
          minWidth: 220,
          sortable: true
        },
        {
          prop: 'title',
          label: 'Title Course',
          minWidth: 280,
          sortable: true
        }
      ],
      tableData: [],
      selectedRows: [],
      showModalEditLesson: false,
      showModalSubmitEditLesson: false,
      showModalDelete: false,
      isEditLesson: false,
      editLesson: {
        id: '',
        title: '',
        pdf: {
          value: '',
          preview: ''
        }
      },
      swapList: [
        { id: null },
        { id: null },
      ],
      selectedDelete: null
    }
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      } else {
        if (this.searchQuery) {
          result = [];
        }
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    ...mapActions('course', ['setCountQNA']),
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    handleCancel() {
      this.isEditLesson = false
      this.$refs.pdfFile.value = null
      this.showModalEditLesson = false
    },
    handleCourse(index, row) {
      this.$router.push({ name: 'detailCourse', params: { id: row.id } })
    },
    cancelSwap() {
      this.swapList[0].id = null
    },
    async handleSwap(row) {
      this.swapList[1].id = row.id
      const payload = {
        first: this.swapList[0].id,
        second: this.swapList[1].id
      }
      try {
        const { data } = await swapOrderLesson(payload)
        this.tableData = data.lesson
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Lesson Successfully exchanged"
        });
        this.showModalEditLesson = false
        this.swapList[0].id = null
        this.swapList[1].id = null
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    uploadPdf() {
      this.isEditLesson = true
      this.editLesson.pdf.value = this.$refs.pdfFile.files[0];
      this.editLesson.pdf.preview = URL.createObjectURL(this.editLesson.pdf.value);
    },
    handlePopUpEdit(index, row) {
      this.editLesson.id = row.id
      this.editLesson.title = row.title
      this.editLesson.pdf.value = row.contents.name
      this.editLesson.pdf.preview = row.contents.realname
      this.showModalEditLesson = true
    },
    handleSubmitAddLessonBack() {
      this.showModalEditLesson = true
      this.showModalSubmitEditLesson = false
    },
    handleEditLesson() {
      this.showModalEditLesson = false
      this.showModalSubmitEditLesson = true
    },
    handleSubmitEditLessonBack() {
      this.showModalEditLesson = true
      this.showModalSubmitEditLesson = false
    },
    async handleSubmitEditLesson() {
      try {
        const form = new FormData();
        form.append('id', this.editLesson.id)
        form.append('title', this.editLesson.title)
        form.append('contents', this.editLesson.pdf.value)
        const { data } = await editLesson(form)
        this.tableData = data.lesson
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.showModalSubmitEditLesson = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Lesson Successfully updated"
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    showPopDelete(value) {
      this.showModalDelete = true
      this.selectedDelete = value
    },
    async handleDelete() {

      try {
        const { data } = await deleteLesson(this.selectedDelete.id)
        this.tableData = data.lesson
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
        this.showModalDelete = false
        this.$notify({
          type: "success",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Lesson Successfully deleted"
        });
        this.selectedDelete = null
      } catch (error) {
        this.selectedDelete = null
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else if (error === "EMPTY_RESULT") {
          this.tableData = []
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          this.showModalDelete = false
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "Delete Succesfull"
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getLesson() {
      try {
        const { data } = await getLessonByCourse(this.$route.params.id)
        this.setCountQNA(data.totalNA)
        this.tableData = data.lesson
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async handlePreviewPDF() {
      try {
        const data = await getFileLesson(this.editLesson.pdf.value)
        const file = new Blob([data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.getLesson()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch
          .search(this.searchQuery)
          .map(({ item }) => item);
      }
      this.searchedData = result;
    },
    activity() {
      this.getLesson()
    },
    showModalEditLesson(val) {
      if (!val) {
        this.isEditLesson = false
        this.$refs.pdfFile.value = null
        this.showModalEditLesson = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}
.wrapper-input-video {
  display: flex;
  align-items: flex-end;
  padding: 0 10px;

  .video-preview {
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;
    width: 220px;
    max-height: 150px;
  }

  .thumbnail-video {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;

    .back-video {
      width: 100%;
      height: 100%;
      background: $tabs-course;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.open-pdf {
        cursor: pointer;
      }
    }

    .photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .button-upload {
    margin-left: 10px;
  }
}

.label-upload {
  margin: 0;
  cursor: pointer;
  .file-upload {
    display: none;
  }
}
.modal-title {
  &.list-lesson {
    padding: 0.5rem 1rem;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .edit-lesson {
    color: $orange-course;
  }
  .delete-lesson {
    color: $alert-ar-vr;
  }
}
</style>