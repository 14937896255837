<template>
  <div>
    <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
      <div class="wrapper-select">
        <span>Show</span>
        <div class="select-row">
          <el-select
            class="select-primary pagination-select"
            size="mini"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <span>Row</span>
      </div>
      <div class="form-search">
        <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
      </div>
    </div>
    <el-table
      :data="queriedData"
      row-key="id"
      header-row-class-name="thead-light"
      @sort-change="sortChange"
      @selection-change="selectionChange"
    >
      <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
      <el-table-column prop="status" label="Status" width="120">
        <template slot-scope="scope">
          <el-tag disable-transitions :class="statusClass(scope.row.status)">{{ scope.row.status }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" align="right" label="Actions">
        <div slot-scope="{$index, row}" class="d-flex">
          <base-button
            v-if="row.status === 'Answered'"
            @click.native="handleViewAnswer($index, row)"
            type="success"
            size="sm"
          >
            <span>View Answer</span>
          </base-button>
          <base-button v-else @click.native="handleAnswer($index, row)" type="detail-vr" size="sm">
            <span>Answer</span>
          </base-button>
        </div>
      </el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class>
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          <span
            v-if="selectedRows.length"
          >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      ></base-pagination>
    </div>
    <modal :show.sync="showModalAnswer" modal-classes="modal-secondary">
      <template slot="header">
        <h3 class="modal-title list-lesson">Answer The Question</h3>
      </template>
      <template slot="close-button"></template>
      <div class="row">
        <div class="col-auto">
          <!-- Avatar -->
          <img
            :alt="selectedQuestion.user.name"
            :src="selectedQuestion.user.avatar"
            class="avatar rounded-circle"
          />
        </div>
        <div class="col ml--2">
          <div class="wrapper-user-name">
            <span class="username">{{ selectedQuestion.user.name }}</span>
            <span class="user-date">{{ selectedQuestion.updatedAt }}</span>
          </div>
          <p class="user-question">{{ selectedQuestion.question }}</p>
          <div v-if="selectedQuestion.contents" class="col-8">
            <div class="thumbnail-video">
              <!-- <div class="back-video"> -->
              <img class="photo" :src="selectedQuestion.contents" alt="image upload by user" />
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>

      <validation-observer v-slot="{ handleSubmit }" ref="formViewAnswer">
        <form role="form" @submit.prevent="handleSubmit(handlePostAnswer)">
          <div class="row mt-4">
            <div class="col">
              <base-input label="Answer" class="mb-3" name="answer">
                <textarea v-model="answerByTrainer" class="form-control" aria-label="With textarea"></textarea>
              </base-input>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <!-- <base-button size="sm" outline type="primary">
                <label class="label-upload">
                  <span>Upload Photo</span>
                  <input
                    hidden="true"
                    class="file-upload"
                    type="file"
                    accept="image/*"
                    ref="imageFile"
                    @change="previewImage"
                  />
                </label>
              </base-button> -->
              <label class="label-upload">
                <div class="btn base-button btn-outline-primary btn-sm">Upload Photo</div>
                <input
                  hidden="true"
                  type="file"
                  accept="image/*"
                  ref="imageFile"
                  @change="previewImage"
                />
              </label>
            </div>
            <div v-if="answerPhoto.value" class="col-8">
              <div class="thumbnail-video">
                <!-- <div class="back-video"> -->
                <img class="photo" :src="answerPhoto.preview" alt="image upload by user" />
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="text-right mt-2">
            <base-button outline type="primary" @click="handleCancelAnswer">Cancel</base-button>
            <base-button type="primary" native-type="submit">Answer</base-button>
          </div>
        </form>
      </validation-observer>
    </modal>
    <modal :show.sync="showModalDetail" modal-classes="modal-secondary">
      <template slot="header">
        <h3 class="modal-title list-lesson">View Answer</h3>
      </template>
      <template slot="close-button"></template>
      <div class="row mb-2">
        <div class="col-auto">
          <!-- Avatar -->
          <img
            :alt="selectedDetails.questioner.name"
            :src="selectedDetails.questioner.avatar"
            class="avatar rounded-circle"
          />
        </div>
        <div class="col ml--2">
          <div class="wrapper-user-name">
            <span class="username">{{ selectedDetails.questioner.name }}</span>
            <span class="user-date">{{ selectedDetails.questioner.date }}</span>
          </div>
          <p class="user-question">{{ selectedDetails.questioner.question }}</p>
          <div class="thumbnail-video" v-if="selectedDetails.questioner.contents">
            <img class="photo" :src="selectedDetails.questioner.contents" alt="image question" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <!-- Avatar -->
          <img
            :alt="selectedDetails.answerer.name"
            :src="selectedDetails.answerer.avatar"
            class="avatar rounded-circle"
          />
        </div>
        <div class="col ml--2">
          <div class="wrapper-user-name">
            <span class="username">{{ selectedDetails.answerer.name }}</span>
            <span class="user-date">{{ selectedDetails.answerer.date }}</span>
          </div>
          <p class="user-question">{{ selectedDetails.answerer.answer }}</p>
          <div class="thumbnail-video" v-if="selectedDetails.answerer.image">
            <img class="photo" :src="selectedDetails.answerer.image" alt="image question" />
          </div>
        </div>
      </div>
      <div class="text-right mt-2">
        <base-button type="danger" @click="showModalDetail = false">Close</base-button>
      </div>
    </modal>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Tag } from 'element-ui'
import { BasePagination } from '@/components';
import { getListQna, getDetailQna, answerQuestion } from '@/services/qna'
import { mapGetters, mapActions } from 'vuex'
import Fuse from "fuse.js";
export default {
  name: 'ListQNA',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tag.name]: Tag
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
      propsToSearch: ['updatedAt', 'user.name', 'question', 'status'],
      tableColumns: [
        {
          prop: 'updatedAt',
          label: 'Date Update',
          minWidth: 180,
          sortable: true
        },
        {
          prop: 'user.name',
          label: 'Name',
          minWidth: 220,
          sortable: true
        },
        {
          prop: 'question',
          label: 'Question',
          minWidth: 200,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      showModalAnswer: false,
      showModalDetail: false,
      selectedQuestion: {
        user: {
          name: ''
        }
      },
      selectedDetails: {
        id: 0,
        questioner: {
          userId: 0,
          name: "",
          question: "",
          date: ""
        },
        answerer: {
          userId: 0,
          name: "",
          answer: "",
          date: ""
        }
      },
      answerByTrainer: '',
      answerPhoto: {
        value: null,
        preview: null
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"]),
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      } else {
        if (this.searchQuery) {
          result = [];
        }
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    ...mapActions('course', ['setCountQNA']),
    statusClass(status) {
      return status === "Answered" ? "pass" : "failed"
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    handleAnswer(index, row) {
      this.selectedQuestion = row
      this.showModalAnswer = true
    },
    previewImage() {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (types.includes(this.$refs.imageFile.files[0].type)) {
        if ((this.$refs.imageFile.files[0].size / 1024).toFixed(2) < 3000) {
          this.answerPhoto.value = this.$refs.imageFile.files[0];
          this.answerPhoto.preview = URL.createObjectURL(this.answerPhoto.value);
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "File size exceeds the limit"
          });
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be png/jpg/jpeg"
        });
      }
    },
    handleCancelAnswer() {
      this.showModalAnswer = false
      this.$refs.imageFile.value = null
      this.answerPhoto.value = null
    },
    async handlePostAnswer() {
      if (this.answerByTrainer || this.answerPhoto.value) {
        try {
          // const payload = {
          //   qnaId: this.selectedQuestion.id,
          //   userId: this.selectedQuestion.user.id,
          //   answer: this.answerByTrainer
          // }
          const form = new FormData()
          form.append('qnaId', this.selectedQuestion.id);
          form.append('userId', this.getInfoAuth.id);
          form.append('answer', this.answerByTrainer);
          form.append('image', this.answerPhoto.value)
          const { data } = await answerQuestion(form)
          const count = data.data.qna.filter(({ status }) => status === "Waiting").length
          this.setCountQNA(count)
          this.tableData = data.data.qna
          this.fuseSearch = new Fuse(this.tableData, {
            keys: this.propsToSearch,
            threshold: 0.3
          });
          this.showModalAnswer = false
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "Answer Successfully added"
          });
          this.answerByTrainer = ''
        } catch (error) {
          if (error.code === 401) {
            this.$cookies.remove('CERT');
            this.$store.dispatch('auth/removeAuth');
            this.$router.replace({ name: "Login" })
            this.$notify({
              type: "danger",
              verticalAlign: "top",
              horizontalAlign: "center",
              closeOnClick: true,
              message: error.msg
            });
          } else {
            this.$notify({
              type: "danger",
              verticalAlign: "top",
              horizontalAlign: "center",
              closeOnClick: true,
              message: error
            });
          }
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: 'please complete your answer'
        });
      }
    },
    async handleViewAnswer(index, row) {
      try {
        const { data } = await getDetailQna(row.id)
        this.selectedDetails = data
        this.showModalDetail = true
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async listQna() {
      try {
        const { data } = await getListQna(this.$route.params.id)
        this.tableData = data.qna
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.listQna()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch
          .search(this.searchQuery)
          .map(({ item }) => item);
      }
      this.searchedData = result;
    },
    showModalAnswer(value) {
      if (value === false) {
        this.$refs.imageFile.value = null
        this.answerPhoto.value = null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";

.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}

.el-tag {
  background-color: #fff !important;
  border-color: #fff !important;
  &.pass {
    color: $status-pass-ar-vr;
  }
  &.learn {
    color: $status-learn-ar-vr;
  }
  &.failed {
    color: $status-failed-ar-vr;
  }
}
.modal-title {
  &.list-lesson {
    padding: 0.5rem 1rem;
  }
}
.wrapper-user-name {
  .username {
    font-size: 14px;
    background: $account-status-ar-vr;
    margin-right: 10px;
    padding: 2px 5px;
    border-radius: 5px;
  }
  .user-date {
    font-size: 12px;
    color: #b0b8bf;
  }
}
.user-question {
  font-size: 14px;
  margin: 0;
}
.label-upload {
  margin: 0;
  cursor: pointer;
}

.video-preview {
  border-radius: 5px;
  background: #fff;
  border: 2px dashed $tabs-course;
  padding: 5px;
  width: 220px;
  max-height: 150px;
}

.thumbnail-video {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background: #fff;
  border: 2px dashed $tabs-course;
  padding: 5px;

  .back-video {
    width: 100%;
    height: 100%;
    background: $tabs-course;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>