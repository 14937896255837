<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <!-- <div class="row d-flex justify-content-between px-2">
              <h2 class="mb-0">List Lesson</h2>
            </div>-->
          </template>
          <div class="container-tabs px-2 mb-4">
            <ul class="wrapper-tabs">
              <li
                v-for="tab in tabs"
                :key="tab.id"
                @click="changeTab(tab.id)"
                :class="{ active: tab.id === activeTab, 'qna-wrapper': tab.id === 4 }"
              >
                <span>{{ tab.name }}</span>
                <span v-if="tab.id === 4 && getCountQNA !== 0" class="count-qna">{{ getCountQNA }}</span>
              </li>
            </ul>
            <!-- <router-link :to="{ name: 'addTrainer' }" class="btn btn-brand-ar-vr">Add New Lesson</router-link> -->
            <base-button
              v-if="activeTab === 1"
              type="detail-vr"
              size="md"
              @click="showingModalAddLesson"
            >Add New Lesson</base-button>
            <div v-if="activeTab === 2">
              <!-- <base-button type="success" @click="handleDownload" size="md">Download Format Exam</base-button> -->
              <base-button
                type="detail-vr"
                size="md"
                @click="handleOpenAddNewQuestion"
              >Add New Question</base-button>
            </div>
          </div>
          <list-lesson :activity="logActivity.addLesson" v-if="activeTab === 1" />
          <list-exam :activity="logActivity.addQuestion" v-else-if="activeTab === 2" />
          <list-participant v-else-if="activeTab === 3" />
          <list-q-n-a v-else-if="activeTab === 4" />
          <list-feedback v-else-if="activeTab === 5" />
        </card>
      </div>
    </div>
    <modal size="lg" :show.sync="modals.showModalAddLesson" modal-classes="modal-secondary">
      <template slot="header">
        <h3 class="modal-title list-lesson">Add New Lesson</h3>
      </template>
      <template slot="close-button"></template>
      <validation-observer v-slot="{ handleSubmit }" ref="formAddLesson">
        <form role="form" @submit.prevent="handleSubmit(handleAddLesson)">
          <div class="row">
            <div class="col-sm-7">
              <base-input
                label="Title Lesson"
                class="mb-3"
                name="Title"
                placeholder="Write title here..."
                v-model="addLesson.title"
                :rules="{ required: true }"
              ></base-input>
            </div>
            <div class="col-sm-5">
              <div class="form-group">
                <label for="pdf" class="form-control-label">PDF File</label>
                <div class="has-label">
                  <div class="wrapper-input-video">
                    <div v-if="!addLesson.pdf.value" class="thumbnail-video">
                      <div class="back-video">
                        <!-- <img src="/img/thumbnail-photo.png" alt="thumbnail-pdf-logo" /> -->
                        <h6>PDF</h6>
                        <!-- <h6 v-else>{{ addLesson.pdf.preview }}</h6> -->
                      </div>
                    </div>
                    <iframe
                      v-else
                      :src="addLesson.pdf.preview"
                      frameborder="0"
                      width="150px"
                      height="200px"
                    ></iframe>
                    <base-button size="sm" outline type="primary" class="button-upload">
                      <label class="label-upload">
                        <div>Upload PDF</div>
                        <input
                          class="file-upload"
                          @change="uploadPdf"
                          type="file"
                          accept="application/pdf"
                          ref="pdfFile"
                        />
                      </label>
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <template slot="footer"> -->
          <div class="text-right mt-4">
            <base-button outline type="primary" @click="modals.showModalAddLesson = false">Cancel</base-button>
            <base-button type="success" native-type="submit">Add New Lesson</base-button>
          </div>
          <!-- </template> -->
        </form>
      </validation-observer>
    </modal>
    <modal :show.sync="modals.showModalAddNewQuestion" modal-classes="modal-secondary" size="lg">
      <template slot="header">
        <h3 class="modal-title list-lesson">Add New Question</h3>
      </template>
      <template slot="close-button"></template>
      <validation-observer v-slot="{ handleSubmit }" ref="formAddNewQuestion">
        <form role="form" @submit.prevent="handleSubmit(handleAddNewQuestion)">
          <div v-for="(addExam, idx) in addExamQuestion" :key="idx">
            <div class="divider-exam"></div>
            <h2 class="text-right">Exam {{ idx + 1 }}</h2>
            <div class="row pb-2 wrapper-preview">
              <div v-if="addExam.image.preview" class="wrapper-img">
                <img :src="addExam.image.preview" class="preview-image" />
                <p>{{ addExam.image.value.name }}</p>
              </div>
              <div class="col-md-6">
                <base-input
                  label="Exam Question"
                  class="mb-1"
                  :name="`exam question ${idx + 1} exam`"
                  placeholder="Write here..."
                  v-model="addExam.question"
                  :rules="{ required: true }"
                ></base-input>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-9 wrapper-image-exam">
                    <!-- <base-button size="lg" type="primary"> -->
                    <label class="label-upload">
                      <div class="btn base-button btn-primary btn-lg">Upload Image</div>
                      <input
                        hidden="true"
                        type="file"
                        accept="image/*"
                        ref="imageFile"
                        @change="previewImage(idx)"
                      />
                    </label>
                    <!-- </base-button> -->
                    <!-- <div class="wrapper-text">
                      <p>{{ addExamQuestion[idx].image.value.name }}</p>
                    </div>-->
                  </div>
                  <!-- <div class="col-3">
                    <base-input label="Point" name="Point" :disabled="true" v-model="addExam.point"></base-input>
                  </div>-->
                  <div class="col-3">
                    <!-- <base-input label="Key" name="Key" v-model="addExam.key"></base-input> -->
                    <base-input
                      label="Key"
                      :name="`answer ${idx + 1} exam`"
                      :rules="{ required: true }"
                    >
                      <select class="form-control" v-model="addExam.key">
                        <template v-if="listKey">
                          <option
                            v-for="choose in listKey"
                            :key="choose.name"
                            :value="choose.value"
                          >{{ choose.name }}</option>
                        </template>
                      </select>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-for="(option, index) in addExam.options" :key="index">
              <div class="col-md-6">
                <base-input
                  :label="option.name"
                  class="mb-1"
                  :name="`${option.name} ${idx + 1} exam`"
                  placeholder="Write here..."
                  v-model="option.value"
                  :rules="{ required: true }"
                ></base-input>
              </div>
            </div>
          </div>
          <!-- <template slot="footer"> -->
          <div class="text-right mt-4">
            <base-button type="outline-vr" @click="handleCancelAddNewQuestion">Cancel</base-button>
            <base-button type="success" @click="AddFormQuestion">Add New Question</base-button>
            <base-button type="detail-vr" native-type="submit">Save & Exit</base-button>
          </div>
          <!-- </template> -->
        </form>
      </validation-observer>
    </modal>
    <modal :show.sync="modals.showSubmitModalAddLesson" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Add New Lesson</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Are you sure to
        <span class="add-lesson">add a new lesson</span> ?
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button outline type="primary" class="my-4" @click="handleSubmitAddLessonBack">Back</base-button>
          <base-button type="success-vr" class="my-4" @click="handleSubmitAddLesson">Add New Lesson</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="modals.showCancelAddNewQuestion" modal-classes="modal-secondary">
      <template slot="header">
        <h6 class="modal-title">Cancel Add New Question?</h6>
      </template>
      <template slot="close-button"></template>
      <span class="body-modal">
        Changes you made so far
        <span class="add-new-question">will not be saved</span>!
      </span>
      <template slot="footer">
        <div class="text-right">
          <base-button type="outline-vr" class="my-4" @click="handleBackAddNewQuestion">Back</base-button>
          <base-button type="danger" class="my-4" @click="handleCloseModaalAddNewQuestion">Leave</base-button>
        </div>
      </template>
    </modal>
    <modal :show.sync="loading.addLesson" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
    <modal :show.sync="loading.addNewQuestion" modal-classes="modal-secondary">
      <loading-panel />
    </modal>
  </div>
</template>
<script>
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import ListLesson from '@/components/List/Courses/ListLesson'
import ListExam from '@/components/List/Courses/ListExam'
import ListParticipant from '@/components/List/Courses/ListParticipant'
import ListQNA from '@/components/List/Courses/ListQNA'
import ListFeedback from '@/components/List/Courses/ListFeedback'
import { addLesson } from '@/services/lesson'
import { downloadFormatQuestion, addNewQuestion } from '@/services/exam'
import { mapGetters } from 'vuex'
import LoadingPanel from '@/components/LoadingPanel.vue';
export default {
  name: "DetailCourses",
  // mixins: [listCoursesMixin],
  components: {
    DashboardNavbar,
    ListLesson,
    ListExam,
    ListParticipant,
    ListQNA,
    LoadingPanel,
    ListFeedback
  },
  data() {
    return {
      routes: [
        {
          name: "listCourses",
          alias: "List Courses"
        },
        {
          name: "listCourses",
          alias: "DETAIL COURSE"
        }
      ],
      tabs: [
        {
          id: 1,
          name: 'Lesson'
        },
        {
          id: 2,
          name: 'Exam Question'
        },
        {
          id: 3,
          name: 'Detail Participant'
        },
        {
          id: 4,
          name: 'QNA'
        },
        {
          id: 5,
          name: 'Feedback'
        },
      ],
      addLesson: {
        title: '',
        pdf: {
          value: '',
          preview: ''
        }
      },
      listKey: [
        { name: 'A', value: 'A', },
        { name: 'B', value: 'B', },
        { name: 'C', value: 'C', },
        { name: 'D', value: 'D', },
        { name: 'E', value: 'E', }
      ],
      addExamQuestion: [
        {
          question: '',
          options: [
            { key: 'A', name: 'Option A', value: '' },
            { key: 'B', name: 'Option B', value: '' },
            { key: 'C', name: 'Option C', value: '' },
            { key: 'D', name: 'Option D', value: '' },
            { key: 'E', name: 'Option E', value: '' }],
          image: { value: '', preview: '' },
          point: 5,
          key: 'A'
        }
      ],
      logActivity: {
        addLesson: false,
        addQuestion: false,
      },
      activeTab: 1,
      modals: {
        showModalAddLesson: false,
        showModalLesson: false,
        showModalAddNewQuestion: false,
        showCancelAddNewQuestion: false,
        showSubmitModalAddLesson: false,
        showSubmitModalLesson: false,
        showSubmitModalAddNewQuestion: false
      },
      loading: {
        addLesson: false,
        addNewQuestion: false
      }
    }
  },
  computed: {
    ...mapGetters("course", ["getCountQNA"]),
  },
  methods: {
    changeTab(evt) {
      this.activeTab = evt
    },
    uploadPdf() {
      if (this.$refs.pdfFile.files[0].type === "application/pdf") {
        this.addLesson.pdf.value = this.$refs.pdfFile.files[0];
        this.addLesson.pdf.preview = URL.createObjectURL(this.addLesson.pdf.value);
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be PDF"
        });
      }
    },
    uploadExcel() {
      const types = [".csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]
      if (types.includes(this.$refs.excelFile.files[0].type)) {
        this.addQuestion.excel.value = this.$refs.excelFile.files[0];
        this.addQuestion.excel.preview = this.$refs.excelFile.files[0].name;
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be Excel"
        });
      }
    },
    showingModalAddLesson() {
      this.modals.showModalAddLesson = true
      this.$refs.pdfFile.value = null
      this.addLesson.title = ''
      this.addLesson.pdf.value = ''
      this.addLesson.pdf.preview = ''
    },
    handleAddLesson() {
      if (this.$refs.pdfFile.value) {
        this.modals.showModalAddLesson = false
        this.modals.showSubmitModalAddLesson = true
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "Lesson must be filled"
        });
      }
    },
    handleSubmitAddLessonBack() {
      this.modals.showModalAddLesson = true
      this.modals.showSubmitModalAddLesson = false
    },
    async handleSubmitAddLesson() {
      this.modals.showSubmitModalAddLesson = false
      try {
        this.loading.addLesson = true
        const form = new FormData();
        form.append('courseId', this.$route.params.id)
        form.append('title', this.addLesson.title)
        form.append('contents', this.addLesson.pdf.value)
        await addLesson(form)
        setTimeout(() => {
          this.loading.addLesson = false
          this.logActivity.addLesson = !this.logActivity.addLesson
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "Lesson Successfully Added"
          });
        }, 2000);
        this.$refs.pdfFile.value = null
        this.addLesson.title = ''
        this.addLesson.pdf.value = ''
        this.addLesson.pdf.preview = ''
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    handleOpenAddNewQuestion() {
      this.modals.showModalAddNewQuestion = true
      this.$refs.imageFile.value = null
      this.addExamQuestion = [{
        question: '',
        options: [
          { key: 'A', name: 'Option A', value: '' },
          { key: 'B', name: 'Option B', value: '' },
          { key: 'C', name: 'Option C', value: '' },
          { key: 'D', name: 'Option D', value: '' },
          { key: 'E', name: 'Option E', value: '' }],
        image: { value: '', preview: '' },
        point: 5,
        key: 'A'
      }]
    },
    handleCancelAddNewQuestion() {
      this.modals.showCancelAddNewQuestion = true
      this.modals.showModalAddNewQuestion = false
    },
    handleBackAddNewQuestion() {
      this.modals.showCancelAddNewQuestion = false
      setTimeout(() => {
        this.modals.showModalAddNewQuestion = true
      }, 100);
    },
    handleCloseModaalAddNewQuestion() {
      this.modals.showModalAddNewQuestion = false
      this.modals.showCancelAddNewQuestion = false
      this.$refs.imageFile.value = null
      this.addExamQuestion = [{
        question: '',
        options: [
          { key: 'A', name: 'Option A', value: '' },
          { key: 'B', name: 'Option B', value: '' },
          { key: 'C', name: 'Option C', value: '' },
          { key: 'D', name: 'Option D', value: '' },
          { key: 'E', name: 'Option E', value: '' }],
        image: { value: '', preview: '' },
        point: 5,
        key: 'A'
      }]
    },
    AddFormQuestion() {
      this.addExamQuestion.push({
        question: '',
        options: [
          { key: 'A', name: 'Option A', value: '' },
          { key: 'B', name: 'Option B', value: '' },
          { key: 'C', name: 'Option C', value: '' },
          { key: 'D', name: 'Option D', value: '' },
          { key: 'E', name: 'Option E', value: '' }],
        image: { value: '', preview: '' },
        point: 5,
        key: 'A'
      })
    },
    previewImage(val) {
      const types = ['image/png', 'image/jpg', 'image/jpeg']
      if (types.includes(this.$refs.imageFile[val].files[0].type)) {
        if ((this.$refs.imageFile[val].files[0].size / 1024).toFixed(2) < 3000) {
          this.addExamQuestion[val].image.value = this.$refs.imageFile[val].files[0];
          this.addExamQuestion[val].image.preview = URL.createObjectURL(this.addExamQuestion[val].image.value);
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: "File size exceeds the limit"
          });
        }
      } else {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: "File must be png/jpg/jpeg"
        });
      }
    },
    async handleAddNewQuestion() {
      // console.log(this.addExamQuestion)
      const form = new FormData();
      this.addExamQuestion.map((item, idx) => {
        form.append(`data[${idx}][courseId]`, this.$route.params.id);
        form.append(`data[${idx}][question]`, item.question);
        form.append(`data[${idx}][key]`, item.key);
        form.append(`data[${idx}][point]`, item.point);
        form.append(`data[${idx}][image]`, item.image.value || "");
        item.options.map((opt, index) => {
          form.append(`data[${idx}][options][${index}][option]`, opt.value);
          form.append(`data[${idx}][options][${index}][key]`, opt.key);
        })
      })
      // console.log(data)
      this.modals.showModalAddNewQuestion = false
      try {
        this.loading.addNewQuestion = true
        // const form = new FormData();
        // form.append('id', this.$route.params.id)
        // form.append('excel', this.addQuestion.excel.value)
        await addNewQuestion(form)
        setTimeout(() => {
          this.loading.addNewQuestion = false
          this.logActivity.addQuestion = !this.logActivity.addQuestion
          this.addExamQuestion = [
            {
              question: '',
              options: [
                { key: 'A', name: 'Option A', value: '' },
                { key: 'B', name: 'Option B', value: '' },
                { key: 'C', name: 'Option C', value: '' },
                { key: 'D', name: 'Option D', value: '' },
                { key: 'E', name: 'Option E', value: '' }],
              image: { value: '', preview: '' },
              point: 5,
              key: 'A'
            }
          ],
            this.$notify({
              type: "success",
              verticalAlign: "top",
              horizontalAlign: "center",
              closeOnClick: true,
              message: "Question Successfully Added"
            });
        }, 2000)
      } catch (error) {
        this.loading.addNewQuestion = false
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    },
    async handleDownload() {
      try {
        const data = await downloadFormatQuestion()
        // const fileURL = URL.createObjectURL(data);
        const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' });
        const elink = document.createElement("a");
        elink.download = "Format-Exam";
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href) // release the URL object
        document.body.removeChild(elink);
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  }
}

</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";

.modal-title {
  &.list-lesson {
    padding: 0.5rem 1rem;
  }
}
.divider-exam {
  height: 2px;
  background: #6e6b7b;
  margin: 10px 0;
  overflow: hidden;
}
.container-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .wrapper-tabs {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0;
    padding: 0;

    .qna-wrapper {
      position: relative;

      .count-qna {
        text-align: center;
        position: absolute;
        font-size: 10px;
        top: 10%;
        right: 20%;
        background-color: rgb(255, 108, 108);
        height: 15px;
        width: 15px;
        border-radius: 50%;
      }
    }

    li {
      background-color: $tabs-course;
      color: #000;
      padding: 10px 35px;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
      transition: all;
      -webkit-transition: all;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:hover {
        color: #fff;
      }

      &.active {
        background-color: $blue-ar-vr;
        color: #fff;
      }
    }
  }
}

.wrapper-input-video {
  display: flex;
  align-items: flex-end;
  padding: 0 10px;

  .video-preview {
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;
    width: 220px;
    max-height: 150px;
  }

  .thumbnail-video {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;

    .back-video {
      width: 100%;
      height: 100%;
      background: $tabs-course;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .photo {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .button-upload {
    margin-left: 10px;
  }
}
.wrapper-image-exam {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 10px;
  .wrapper-text {
    overflow-x: scroll;
    max-width: 60px;

    p {
      white-space: nowrap;
      font-size: 14px;
      margin: 0;
    }
  }
}

.label-upload {
  margin: 0;
  cursor: pointer;
  .file-upload {
    display: none;
  }
}
.body-modal {
  font-size: 20px;
  font-weight: 600;
  .add-lesson {
    color: $status-pass-ar-vr;
  }
  .add-new-question {
    color: $status-failed-ar-vr;
  }
}

// to preview image at add new exam question
.wrapper-preview {
  position: relative;
  .wrapper-img {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 200px;
    height: 200px;
    border-radius: 5px;
    background: #fff;
    border: 2px dashed $tabs-course;
    padding: 5px;

    p {
      margin: 10px 0 0 0;
      text-align: center;
    }
    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>