import { api } from "./axios";

const getLessonByCourse = id => {
  return api.get(`v1/lesson/${id}`);
};

const deleteLesson = id => {
  return api.delete(`v1/lesson?id=${id}`);
};

const addLesson = data => {
  return api.post("v1/lesson", data);
};

const editLesson = data => {
  return api.patch("v1/lesson", data);
};

const swapOrderLesson = data => {
  return api.put("v1/lesson", data);
};

const getFileLesson = content => {
  return api.get(`v1/lesson/file?contents=${content}`, {
    responseType: "blob"
  });
};

export {
  getFileLesson,
  getLessonByCourse,
  addLesson,
  editLesson,
  deleteLesson,
  swapOrderLesson
};
